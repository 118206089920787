<template>
  <div>
    <v-list
      two-line
      subheader
    >
      <v-subheader>MATLAB</v-subheader>

      <v-list-item>
        <v-list-item-avatar>
          <v-icon>mdi-code-greater-than</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>MATLAB</v-list-item-title>
          <v-list-item-subtitle>Execute code to generate plot</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn
            icon
            ripple
          >
            <v-icon
              @click="writeCodeToClipboard(matlabCode)"
            >
              mdi-content-copy
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-card-text
      v-if="writeRun"
      class="pt-0 mt-0"
    >
      <pre v-highlightjs><code class="matlab">{{ matlabCode }}</code></pre>
    </v-card-text>
  </div>
</template>

<script>
import { EventBus } from '../../scripts/event_bus'

import { mapGetters } from 'vuex'
export default {
  name: 'AnythingElse',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    led: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data () {
    return {
      matlabCode: '',
      writeRun: false
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  watch: {
    data: {
      handler: function (newValue) {
        this.writeMatlab()
      },
      deep: true
    }
  },
  mounted () {
    //      let gnuplotScript = document.createElement('script')
    //      gnuplotScript.setAttribute('src', 'https://cmsdb.darkcosmos.org/gnuplot.js/addcss.js')
    //      document.head.appendChild(gnuplotScript)
    this.writeMatlab()
  },
  methods: {
    writeCodeToClipboard: function (codeToCopy) {
      console.log('Writing', codeToCopy, 'to clipboard')

      window.navigator.clipboard.writeText(codeToCopy).then(() => {
        EventBus.$emit('info', 'Matlab code copied to clipboard successfully')
      }, () => {
        EventBus.$emit('info', 'Copy to clipboard failed... Outdated browser?')
      })
    },
    writeMatlab: function () {
      this.writeRun = false
      const dbs = []
      const aos = []

      if (this.data.files) {
        if (this.data.files[0]) {
          if (this.data.files[0].aos) {
            this.data.files[0].aos.forEach(function (element) {
              aos.push("'" + element.ao_id + "'")
              dbs.push("'" + element.ltpda + "'")
            })
          }
        }
      }

      this.matlabCode = 'clear all;\nclose all;\n\n' +
        '% Get AOs\n' +
        'db = {' + dbs.join(', ') + '};\n' +
          'uuids = {' + aos.join(', ') + '};\n' +
          '\n' +
          'aos = [];\n' +
          'for ii = 1:numel(uuids)\n' +
          'aos = [aos ao(plist(\'hostname\', \'localhost\', ...\n' +
          this.data.ltpda +
          '\'database\', db{ii}, ...\n' +
          '\'uuid\', uuids{ii}))];\n' +
          'end\n' +
          '\n' +
          'iplot(aos);\n'
      this.writeRun = true
    }
  }
}
</script>
<style scoped>
</style>
